import React from 'react'

import styled from 'styled-components'

import { Link } from 'gatsby'

export default styled(({ className, target, label }) => {
  return (
    <Link className={className} to={target} activeClassName="active">
      {label}
    </Link>
  )
})`
  display: inline-block;
  height: 100%;
  padding: 9px 10px;
  margin: 0;

  margin-top: ${props => props.theme.dimen.banner._.top};

  ${props => props.theme.media.md`
    margin-top: ${props => props.theme.dimen.banner.md.top};
  `}

  ${props => props.theme.media.lg`
    margin-top: ${props => props.theme.dimen.banner.lg.top};
  `}

  color: ${props =>
    props.theme.colors.foreground(
      props.actionColors &&
        props.actionColors.fg &&
        props.actionColors.fg.normal
    )};
  font-size: ${props => props.theme.font._.h1.size};
  background-color: ${props =>
    props.theme.colors.background(
      props.actionColors &&
        props.actionColors.bg &&
        props.actionColors.bg.normal
    )};
  font-weight: ${props => props.theme.fontWeights.strong};
  transition: all 0.15s ease-in;

  text-decoration: none;

  ${props => props.theme.media.sm`
    padding: 20px 57px;
  `};

  &:hover {
    color: ${props =>
      props.theme.colors.foreground(
        props.actionColors &&
          props.actionColors.fg &&
          props.actionColors.fg.hover
      )};
    background-color: ${props =>
      props.theme.colors.background(
        props.actionColors &&
          props.actionColors.bg &&
          props.actionColors.bg.hover
      )};
  }
`
