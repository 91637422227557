import React, { Fragment } from 'react'
import { MarkdownProvider } from 'react-markdown-tree'

import styled from 'styled-components'

import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import ActionButton from '@components/banner/action-button'

const displayEnabled = backgroundImage => {
  if (backgroundImage === undefined) {
    return false
  }

  return backgroundImage.display === true
}
const BackgroundImageComponent = ({ image, height, animation }) => {
  if (image === null || image === undefined) return <Fragment />

  return (
    <div className="bg" style={{ height: `${height}px` }}>
      <Img fluid={image.path.childImageSharp.fluid} style={{ animation }} />
    </div>
  )
}

const AttributionComponent = ({ image, animation }) => {
  if (image === null || image === undefined) return <Fragment />

  return (
    <div
      className="attribution"
      dangerouslySetInnerHTML={{ __html: image.attribution }}
      style={{ animation }}
    />
  )
}

class LightMasthead extends React.Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.state = {
      backgroundHeight: 0,
    }
    this.updateBackgroundHeight = this.updateBackgroundHeight.bind(this)
  }

  updateBackgroundHeight() {
    if (
      this.ref !== undefined &&
      this.ref.current !== undefined &&
      this.ref.current !== null
    ) {
      this.setState({
        backgroundHeight: this.ref.current.clientHeight,
      })
    }
  }

  componentDidMount() {
    this.updateBackgroundHeight()
    window.addEventListener('resize', this.updateBackgroundHeight)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateBackgroundHeight)
  }

  render() {
    const animation = (height => {
      if (height === 0) {
        return 'none'
      } else {
        return 'showBackground 1s ease-in'
      }
    })(this.state.backgroundHeight)

    return (
      <MarkdownProvider>
        <section className={this.props.className} ref={this.ref}>
          {displayEnabled(this.state.backgroundImage) && (
            <BackgroundImageComponent
              height={this.state.backgroundHeight}
              image={this.props.backgroundImage}
              animation={animation}
            />
          )}

          <div className="fg">
            <div>
              <h1>
                {this.props.title && <strong>{this.props.title}</strong>}
                {this.props.subtitle}
              </h1>
            </div>

            {this.props.children && <section>{this.props.children}</section>}

            {this.props.actionDisplay && (
              <nav>
                <ActionButton
                  target={this.props.actionTarget}
                  label={this.props.actionLabel}
                  actionColors={this.props.actionColors}
                />
              </nav>
            )}
          </div>

          {false && (
            <AttributionComponent
              image={this.props.backgroundImage}
              animation={animation}
            />
          )}
        </section>
      </MarkdownProvider>
    )
  }
}

export const query = graphql`
  fragment LightMastheadFragment on MarkdownRemark {
    frontmatter {
      masthead {
        backgroundImage {
          path {
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          attribution
        }
        colors {
          background
          text
          marker
          action {
            fg {
              normal
              hover
            }
            bg {
              normal
              hover
            }
          }
        }
        title
        subtitle
        action {
          display
          label
          target
        }
        body
        show
        text
      }
    }
  }
`

export default styled(LightMasthead)`
  overflow: hidden;
  min-height: 250px;
  ${props => props.theme.media.md`
    min-height: 350px;
  `}

  @keyframes showBackground {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  div.attribution {
    display: none;
    ${props => props.theme.media.md`
      display: flex;
    `}

    z-index: 0;
    position: relative;
    padding: 0;
    margin: 0;
    width: auto;
    height: auto;
    justify-content: right;

    > p {
      color: ${props => props.theme.colors.background('opaqueWhite')};
      margin: ${props => props.theme.dimen.line.x};
      > a {
        color: ${props => props.theme.colors.background('opaqueWhite')};
        &:hover {
          text-decoration: underline;
        }
      }
    }

    > a {
      position: relative;
      bottom: 0;
      margin: ${props => props.theme.dimen.line.x};
      right: 0;
      height: auto;
      opacity: 0.25;
      bottom: 0;
    }
  }

  div.bg {
    position: absolute;
    overflow: hidden;
    padding: 0;
    width: 100%;
    z-index: -50;
    background-color: ${props => props.theme.colors.background('dark')};

    div.gatsby-image-wrapper {
      min-height: 100%;
      min-width: 100%;
      transform: scale(1.1);
      background-size: cover;
      filter: blur(10px);
      //top: -50%;
    }
  }

  div.fg {
    position: relative;

    padding-top: ${props => props.theme.dimen.banner._.top};
    padding-bottom: ${props => props.theme.dimen.banner._.bottom};

    ${props => props.theme.media.md`
      padding-top: ${props => props.theme.dimen.banner.md.top};
      padding-bottom: ${props => props.theme.dimen.banner.md.bottom};
    `}

    ${props => props.theme.media.lg`
      padding-top: ${props => props.theme.dimen.banner.lg.top};
      padding-bottom: ${props => props.theme.dimen.banner.lg.bottom};
    `}

    display: block;

    > div {
      max-width: 70%;
    }

    h1,
    h2,
    p {
      display: inline-block;

      color: ${props => props.theme.colors.foreground(props.textColor)};
      background-color: ${props =>
        props.theme.colors.background(props.markerColor)};

      padding-left: ${props =>
        props.markerColor ? props.theme.dimen.line.x : 'none'};
      padding-right: ${props =>
        props.markerColor ? props.theme.dimen.line.x : 'none'};

      a {
        color: ${props => props.theme.colors.foreground('main')};
        text-decoration: none;
        font-weight: ${props => props.theme.fontWeights.strong};
        &:hover {
          text-decoration: underline;
        }
      }
    }

    p:not(:first-child) {
      display: none;
      ${props => props.theme.media.sm`
        display: inline-block;
      `}
    }

    strong {
      text-transform: none;
      font-weight: ${props => props.theme.fontWeights.strong};
    }

    h1,
    h2,
    p {
      padding-bottom: ${props => props.theme.dimen.line.y};
      padding-top: ${props => props.theme.dimen.line.y};

      font-weight: ${props => props.theme.fontWeights.semiRegular};

      font-size: ${props => props.theme.font._.h1.size};
      line-height: ${props => props.theme.font._.h1.lineHeight};
      ${props => props.theme.media.sm`
        font-size: ${props => props.theme.font.sm.h1.size};
        line-height: ${props => props.theme.font.sm.h1.lineHeight};
      `}
      ${props => props.theme.media.md`
        font-size: ${props => props.theme.font.md.h1.size};
        line-height: ${props => props.theme.font.md.h1.lineHeight};
      `}
    }

    > h1:not(:last-child),
    > h2:not(:last-child),
    > p:not(:last-child),
    > div:not(:last-child),
    > nav:not(:last-child),
    > section {
      margin-bottom: ${props =>
        props.markerColor ? props.theme.dimen.line.y : 'none'};
    }
  }
`
