import React from 'react'
import styled from 'styled-components'

const Text = ({ className, content }) => {
  return (
    <section
      className={className}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}

export default styled(Text)`
  padding-top: ${props => props.theme.dimen.main.y};
  padding-bottom: ${props => props.theme.dimen.main.y};

  font-size: ${props => props.theme.font._.p.size};
  line-height: ${props => props.theme.font._.p.lineHeight};

  ${props => props.theme.media.sm`
    font-size: ${props => props.theme.font.sm.p.size};
    line-height: ${props => props.theme.font.sm.p.lineHeight};
  `}

  ${props => props.theme.media.md`
    font-size: ${props => props.theme.font.md.p.size};
    line-height: ${props => props.theme.font.md.p.lineHeight};
  `}

  p,
  h1,
  h2,
  h3 {
    padding-top: ${props => props.theme.dimen.line.y};
  }

  h1 {
    font-size: ${props => props.theme.font._.h1.size};
    line-height: ${props => props.theme.font._.h1.lineHeight};
    font-weight: ${props => props.theme.fontWeights.strong};

    ${props => props.theme.media.sm`
      font-size: ${props => props.theme.font.sm.h1.size};
      line-height: ${props => props.theme.font.sm.h1.lineHeight};
    `}

    ${props => props.theme.media.md`
      font-size: ${props => props.theme.font.md.h1.size};
      line-height: ${props => props.theme.font.md.h1.lineHeight};
    `}
  }

  h2 {
    font-size: ${props => props.theme.font._.h2.size};
    line-height: ${props => props.theme.font._.h2.lineHeight};
    font-weight: ${props => props.theme.fontWeights.strong};

    ${props => props.theme.media.sm`
      font-size: ${props => props.theme.font.sm.h2.size};
      line-height: ${props => props.theme.font.sm.h2.lineHeight};
    `}

    ${props => props.theme.media.md`
      font-size: ${props => props.theme.font.md.h2.size};
      line-height: ${props => props.theme.font.md.h2.lineHeight};
    `}
  }

  h3 {
    font-size: ${props => props.theme.font._.h3.size};
    line-height: ${props => props.theme.font._.h3.lineHeight};
    font-weight: ${props => props.theme.fontWeights.strong};

    ${props => props.theme.media.sm`
      font-size: ${props => props.theme.font.sm.h3.size};
      line-height: ${props => props.theme.font.sm.h3.lineHeight};
    `}

    ${props => props.theme.media.md`
      font-size: ${props => props.theme.font.md.h3.size};
      line-height: ${props => props.theme.font.md.h3.lineHeight};
    `}
  }

  h4 {
    font-size: ${props => props.theme.font._.h4.size};
    line-height: ${props => props.theme.font._.h4.lineHeight};
    font-weight: ${props => props.theme.fontWeights.strong};

    ${props => props.theme.media.sm`
      font-size: ${props => props.theme.font.sm.h4.size};
      line-height: ${props => props.theme.font.sm.h4.lineHeight};
    `}

    ${props => props.theme.media.md`
      font-size: ${props => props.theme.font.md.h4.size};
      line-height: ${props => props.theme.font.md.h4.lineHeight};
    `}
  }

  strong {
    font-weight: ${props => props.theme.fontWeights.strong};
  }

  blockquote {
    margin-top: ${props => props.theme.dimen.blockquote.marginTop};
    padding: ${props => props.theme.dimen.blockquote.side};
    background-color: ${props => props.theme.colors.aside.background};

    p {
      padding-top: ${props => props.theme.dimen.line.y};
      &:first-child {
        padding-top: 0;
      }
    }
  }

  > ul,
  > ol {
    list-style: disc;
    padding-left: ${props => props.theme.dimen.line.y};
  }
`
