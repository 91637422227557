import React from 'react'
import { Markdown } from 'react-markdown-tree'

import { graphql } from 'gatsby'

import Layout from '@components/layout'

import BannerList from '@components/banner/list'
import Masthead from '@components/banner/masthead'
import Note from '@components/banner/note'
import LinkList from '@components/banner/link-list'

import LightMasthead from '@components/banner/light-masthead'

export default ({
  data: {
    allServicesYaml,
    indexServices,
    markdownRemark: {
      html,
      frontmatter: { title, services, keywords, masthead, note },
    },
  },
  location,
}) => {
  const links = (services.index === true ? indexServices : allServicesYaml)
    .edges

  const servicesComponent = (
    <Masthead
      backgroundColor={services.colors.background}
      markerColor={services.colors.marker}
      title={services.title}
      subtitle={services.subtitle}
    >
      <LinkList
        items={links.map(link => ({
          title: link.node.name,
          link: link.node.url,
          image: link.node.image,
        }))}
      />
    </Masthead>
  )

  return (
    <Layout title={title} keywords={keywords} pathname={location.pathname}>
      <BannerList>
        {masthead && masthead.show && (
          <LightMasthead
            backgroundImage={masthead.backgroundImage}
            backgroundColor={masthead.colors.background}
            textColor={masthead.colors.text}
            markerColor={masthead.colors.marker}
            actionColors={masthead.colors.action}
            title={masthead.title}
            subtitle={masthead.subtitle}
            actionLabel={masthead.action.label}
            actionTarget={masthead.action.target}
            actionDisplay={masthead.action.display}
          >
            {masthead.text && <Markdown>{masthead.text}</Markdown>}
          </LightMasthead>
        )}
        {services && services.showBeforeNote && servicesComponent}
        {note.show && (
          <Note backgroundColor={note.backgroundColor} content={html} />
        )}
        {services && services.showAfterNote && servicesComponent}
      </BannerList>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date
        title
        keywords
        note {
          show
          backgroundColor
        }
        services {
          colors {
            background
            text
            marker
          }
          title
          index
          marked
          showBeforeNote
          showAfterNote
        }
      }
      ...LightMastheadFragment
    }
    indexServices: allServicesYaml(
      filter: { url: { ne: $slug }, index: { eq: true } }
    ) {
      edges {
        node {
          index
          name
          url
          image {
            childImageSharp {
              fluid(maxWidth: 450) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
    allServicesYaml(filter: { url: { ne: $slug } }) {
      edges {
        node {
          name
          url
          image {
            childImageSharp {
              fluid(maxWidth: 450) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`
