import React from 'react'
import styled from 'styled-components'

// TODO: Remove `> section` definition should be composed into the
// components itself
export default styled(({ className, children }) => {
  return <section className={className}>{children}</section>
})`
  > section {
    > div,
    > header,
    > ol {
      background: ${props => props.theme.colors.background(props.blockColor)};

      padding-right: ${props => props.theme.dimen.line.x};
      padding-left: ${props => props.theme.dimen.line.x};

      ${props => props.theme.media.xl`
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        width: ${props => props.theme.breakpoints.xl}px;
      `}
    }
  }
`
