import React from 'react'
import { graphql } from 'gatsby'

import styled from 'styled-components'
import { grid } from 'styled-components-grid'
import Text from '@components/text'

const Note = ({ className, content, backgroundColor }) => {
  return (
    <article className={className}>
      <Text content={content} />
    </article>
  )
}

export const query = graphql`
  fragment NoteFragment on MarkdownRemark {
    frontmatter {
      note {
        show
        backgroundColor
      }
    }
  }
`

export default styled(Note)`
  ${grid({})}
  display: 

  justify-content: space-between;
  align-items: center;

  background: ${props => props.theme.colors.background(props.backgroundColor)};

  margin-left: auto;
  margin-right: auto;

  padding-left: ${props => props.theme.dimen.main.x};
  padding-right: ${props => props.theme.dimen.main.x};

  ${props => props.theme.media.xl`
    padding-left: 0;
    padding-right: 0;
    margin-top: ${props => props.theme.dimen.main.y};
    margin-bottom: ${props => props.theme.dimen.main.y};
    width: ${props => props.theme.breakpoints.xl}px;
  `}

  header {
    ${grid.unit({ size: 1 })}

    h1, p, nav {
      max-width: ${props => props.theme.breakpoints.md}px;
      margin-left: auto;
      margin-right: auto;

      padding-top: ${props => props.theme.dimen.articleTitle.top};
      padding-bottom: ${props => props.theme.dimen.articleTitle.bottom};
    }

    time {
      width: 100%;
      ${props => props.theme.media.md`
        width: auto;
      `}
    }

    time, li {
      display: inline-block;

      text-transform: uppercase;

      padding-top: ${props => props.theme.dimen.tag.y};
      padding-bottom: ${props => props.theme.dimen.tag.y};

      padding-right: ${props => props.theme.dimen.tag.x};

      a {
        color: ${props => props.theme.colors.foreground('dark')};
        text-decoration: none;
        :hover {
          text-decoration: underline;
        }
      }
    }

    ul > li, ol > li:not(:first-child) {
      padding-left: 0;
      display: block;

      ${props => props.theme.media.sm`
        display: inline-block;
        padding-left: ${props => props.theme.dimen.tag.x};
      `}
    }

    time, li:not(:last-child) {
      ${props => props.theme.media.sm`
        border-right: 1px solid ${props =>
          props.theme.colors.foreground('light')};
      `}
    }

    ul {
      display: none;
      ${props => props.theme.media.md`
        display: inline-block;
      `}
    }

    h1 {
      font-size: ${props => props.theme.font._.h1.size};
      line-height: ${props => props.theme.font._.h1.lineHeight};
      font-weight: ${props => props.theme.fontWeights.strong};

      ${props => props.theme.media.sm`
        font-size: ${props => props.theme.font.sm.h1.size};
        line-height: ${props => props.theme.font.sm.h1.lineHeight};
      `}

      ${props => props.theme.media.md`
        font-size: ${props => props.theme.font.md.h1.size};
        line-height: ${props => props.theme.font.md.h1.lineHeight};
      `}
    }

    p {
      font-size: ${props => props.theme.font._.p.size};
      line-height: ${props => props.theme.font._.p.lineHeight};

      ${props => props.theme.media.sm`
        font-size: ${props => props.theme.font.sm.p.size};
        line-height: ${props => props.theme.font.sm.p.lineHeight};
      `}

      ${props => props.theme.media.md`
        font-size: ${props => props.theme.font.md.p.size};
        line-height: ${props => props.theme.font.md.p.lineHeight};
      `}

      padding-bottom: ${props => props.theme.dimen.articlePreamble.bottom};
    }

  }

  > p, > section {
    ${grid.unit({ size: 1 })}
    max-width: ${props => props.theme.breakpoints.md}px;

    margin-left: auto;
    margin-right: auto;
  }
`
