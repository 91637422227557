import React from 'react'

import styled from 'styled-components'

const BannerBody = styled(({ className, content }) => (
  <div className={className}>{content}</div>
))`
  max-width: 30rem;

  color: ${props => props.theme.colors.foreground(props.textColor)};

  font-size: ${props => props.theme.font._.p.size};
  line-height: ${props => props.theme.font._.p.lineHeight};

  ${props => props.theme.media.sm`
    font-size: ${props => props.theme.font.sm.p.size};
    line-height: ${props => props.theme.font.sm.p.lineHeight};
  `}

  ${props => props.theme.media.md`
    font-size: ${props => props.theme.font.md.p.size};
    line-height: ${props => props.theme.font.md.p.lineHeight};
  `}
`

export default styled(({ className, textColor, title, subtitle, lead }) => (
  <header className={className}>
    {title && (
      <div>
        <h2>{title}</h2>
      </div>
    )}
    {subtitle && (
      <div>
        <h3>{subtitle}</h3>
      </div>
    )}
    {lead && <BannerBody content={lead} />}
  </header>
))`
  margin-top: -${props => props.theme.dimen.line.y};

  margin-bottom: ${props => props.theme.dimen.banner._.headerBottom};
  ${props => props.theme.media.md`
    margin-bottom: ${props => props.theme.dimen.banner.md.headerBottom};
  `};

  > div {
    width: 100%;
    &:not(:first-child) {
      margin-top: ${props => props.theme.dimen.line.y};
    }
    margin-top: ${props => props.theme.dimen.line.y};
  }

  h2, h3, p {
    &:not(:first-child) {
      padding-top: ${props => props.theme.dimen.line.y};
    }
  }

  h2 {
    font-weight: ${props => props.theme.fontWeights.strong};

    font-size: ${props => props.theme.font._.h1.size};
    line-height: ${props => props.theme.font._.h1.lineHeight};

    ${props => props.theme.media.sm`
      font-size: ${props => props.theme.font.sm.h1.size};
      line-height: ${props => props.theme.font.sm.h1.lineHeight};
    `}

    ${props => props.theme.media.md`
      font-size: ${props => props.theme.font.md.h1.size};
      line-height: ${props => props.theme.font.md.h1.lineHeight};
    `}

    color: ${props => props.theme.colors.foreground(props.textColor)};
  }

  h3 {
    font-weight: ${props => props.theme.fontWeights.strong};

    font-size: ${props => props.theme.font._.h2.size};
    line-height: ${props => props.theme.font._.h2.lineHeight};

    padding-right: ${props => props.theme.dimen.line.x};
    ${props => props.theme.media.sm`
      font-size: ${props => props.theme.font.sm.h2.size};
      line-height: ${props => props.theme.font.sm.h2.lineHeight};
    `}

    ${props => props.theme.media.md`
      font-size: ${props => props.theme.font.md.h2.size};
      line-height: ${props => props.theme.font.md.h2.lineHeight};
    `}

    color: ${props => props.theme.colors.foreground(props.textColor)};
  }


  a {
    color: ${props => props.theme.colors.foreground(props.textColor)};
  }
`
