import React from 'react'
import styled from 'styled-components'
import { grid } from 'styled-components-grid'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

// NOTE: The image fed into this component cannot be SVG's as fluid
// representations are not possible on those inputs.
export default styled(
  ({
    className,
    backgroundColor,
    backgroundHoverColor,
    textColor,
    textHoverColor,
    items,
    imagePlaceholder,
  }) => {
    return (
      <ul className={className}>
        {items.map((item, i) => {
          const image = (item && item.image) || imagePlaceholder

          return (
            <li key={i}>
              <Link to={item.link}>
                <div>
                  {image && <Img fluid={image.childImageSharp.fluid} />}
                </div>
                <header>
                  <span>{item.title}</span>
                </header>
              </Link>
            </li>
          )
        })}
      </ul>
    )
  }
)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: -${props => props.theme.dimen.line.y};

  li {
    width: 100%;

    background-color: ${props =>
      props.theme.colors.background(props.backgroundColor)};

    box-sizing: border-box;
    margin-right: none;
    margin-top: ${props => props.theme.dimen.line.y};

    font-weight: ${props => props.theme.fontWeights.strong};

    > a,
    > div {
      display: block;
      width: 100%;
      text-decoration: none;
      color: ${props => props.theme.colors.foreground(props.textColor)};

      > header {
        margin: 0;
        text-align: center;
      }
    }

    transition: all 0.15s ease-in;
    &:hover {
      background-color: ${props =>
        props.theme.colors.background(props.backgroundHoverColor)};
      > a,
      > div {
        color: ${props => props.theme.colors.foreground(props.textHoverColor)};
      }
    }

    > a {
      ${grid({})}
      > div {
        ${grid.unit({ size: { xs: 1 / 3, md: 1 / 4, lg: 1 } })}
      }
      > header {
        ${grid.unit({ size: { xs: 2 / 3, md: 3 / 4, lg: 1 } })}
      }
    }

    ${props => props.theme.media.xs`
      font-size: ${props => props.theme.font.sm.p.size};
      line-height: ${props => props.theme.font.sm.p.lineHeight};

      > a {
        > div {
          margin-top: auto;
          margin-bottom: auto;
        }
        > header {
          text-align: left;
          vertical-align: middle;
          margin-top: auto;
          margin-bottom: auto;
          padding: 1rem;
        }
      }
    `}

    ${props => props.theme.media.lg`
      width: 32%;

      > a {
        > header {
          text-align: center;
        }
      }

      &:nth-child(3n+1),
      &:nth-child(3n+2)
      {
        margin-right: 2%;
      }
      &:nth-child(3n+3) {
        margin-right: 0;
      }
    `}
  }
`
