import React from 'react'
import styled from 'styled-components'

import BannerHeader from '@components/banner-header'

export default styled(
  ({
    className,
    children,
    textColor,
    marked,
    markerColor,
    backgroundColor,
    title,
    subtitle,
    lead,
  }) => {
    return (
      <section className={className}>
        <BannerHeader
          textColor={textColor}
          marked={marked || false}
          markerColor={markerColor}
          backgroundColor={backgroundColor}
          title={title}
          subtitle={subtitle}
          lead={lead}
        />

        {children && <div>{children}</div>}
      </section>
    )
  }
)`
  background-color: ${props =>
    props.theme.colors.background(props.backgroundColor)};

  color: ${props => props.theme.colors.foreground(props.textColor)};

  padding-top: ${props => props.theme.dimen.banner._.top};
  padding-bottom: ${props => props.theme.dimen.banner._.bottom};

  ${props => props.theme.media.md`
    padding-top: ${props => props.theme.dimen.banner.md.top};
    padding-bottom: ${props => props.theme.dimen.banner.md.bottom};
  `}

  ${props => props.theme.media.lg`
    padding-top: ${props => props.theme.dimen.banner.lg.top};
    padding-bottom: ${props => props.theme.dimen.banner.lg.bottom};
  `}
`
